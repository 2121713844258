import React, { LegacyRef } from "react";
import styles from "./CircleButton.module.css";

type CircleButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  focused?: boolean
}
const CircleButton = React.forwardRef((
    {
      focused,
      children,
      ...props
    }: CircleButtonProps, 
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    return (
      <button
        type="button"
        
        ref={ref}
        
        onMouseLeave={(e) => {
          e.currentTarget.blur();
          props.onMouseLeave?.call(null, e);
        }}

        {...props}

        className={`${styles.wrapper} ${focused && styles.focused} ${props.className}`}
      >
        {children}
      </button>
    );
  }
);

export default CircleButton;
