import React, { useState } from "react";
import styles from "./CalendarLogin.module.css";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import {
  redux_user_actions,
  selectUser,
} from "src/redux/features/user/userSlice";
import { useGoogleLogin } from "@react-oauth/google";
import { api_calendars, api_loginauth } from "src/api/functions";
import { useLocation, useNavigate } from "react-router-dom";
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { redux_calendar_actions } from "src/redux/features/calendars/calendarsSlice";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClickAwayListener, Grow, Popper } from "@mui/material";
import CircleButton from "src/components/buttons/CircleButton";
import { DPButton } from "src/components/buttons/DPButton";
import CalendarLoginButton from "./CalendarLoginButton";

function CalendarLogin({ children, ...props }: any) {
  /** REACT ROUTER */
  const navigate = useNavigate();
  const location = useLocation();

  /** REDUX */
  const dispatch = useAppDispatch();
  const { loggedIn, info, preferences } = useAppSelector(selectUser);

  /** LOCAL STATES */
  const [open, setOpen] = useState(false);

  const togglePopper = () => {
    setOpen((old) => !old);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popper, setPopper] = useState<null | HTMLDivElement>(null);

  const _loginAuthcode = useGoogleLogin({
    redirect_uri: "denispanda.com",
    onSuccess: async ({ code }) => {
      const { data: user } = await api_loginauth(code);
      if (!user) return;
      dispatch(
        redux_user_actions.load({
          loggedIn: true,
          info: user.info,
          preferences: user.preferences,
        })
      );

      const { data: calendars } = await api_calendars(user.info);
      if (!calendars) return;
      dispatch(redux_calendar_actions.load(calendars));
      navigate(location.state?.from || "/calendars");
    },
    scope: "openid email profile https://www.googleapis.com/auth/calendar",
    flow: "auth-code",
    select_account: true,
  });

  const _logout = () => {
    dispatch(redux_user_actions.clear());
    dispatch(redux_calendar_actions.clear());
  };

  return (
    <>
      <CircleButton
        type="button"
        ref={setAnchorEl}
        onClick={togglePopper}
        focused={open}
        style={{
          overflow: "hidden",
        }}
      >
        {!loggedIn ? (
          // Log In Icon
          <FontAwesomeIcon icon={faArrowRightToBracket} style={{}} />
        ) : info.picture ? (
          // Profile Icon
          <img
            style={{
              position: "absolute",
              inset: 0,
            }}
            src={info.picture || ""}
          ></img>
        ) : (
          // Log Out Icon
          <FontAwesomeIcon
            icon={faArrowRightFromBracket}
            style={{
              position: "absolute",
              inset: 0,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
      </CircleButton>

      <Popper
        id={open ? "login-popper" : undefined}
        open={open}
        style={{
          zIndex: 999,
        }}
        placement="bottom-end"
        anchorEl={anchorEl}
        transition={true}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            in={open}
            style={{ transformOrigin: "top right" }}
            timeout={100}
            easing={"cubic-bezier(0.4, 0, 0.6, 1)"}
          >
            <div
              className={`${styles.loginWrapper}`}
              onMouseLeave={(e) => {
                popper?.classList.remove(styles.focused);
              }}
            >
              <ClickAwayListener
                onClickAway={() => {
                  setOpen(false);
                }}
              >
                <div
                  ref={setPopper}
                  className={`${styles.loginPopper} ${open && styles.focused}`}
                >
                  <div className={`${styles.loginHeading}`}>Account</div>
                  {!loggedIn ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        maxWidth: "100%",
                      }}
                    >
                      <CalendarLoginButton />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <DPButton
                        style={{
                          marginTop: "6pt",
                          placeSelf: "center",
                        }}
                      >
                        {info.name}
                      </DPButton>

                      <DPButton
                        onClick={() => {
                          _logout();
                        }}
                        style={{
                          marginTop: "6pt",
                          placeSelf: "center",
                        }}
                      >
                        Log Out
                      </DPButton>
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default CalendarLogin;
