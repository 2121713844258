import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import calendarsSlice from './features/calendars/calendarsSlice';
import userSlice from './features/user/userSlice';
import { api_prefs } from '../api/functions';

// convert object to string and store in localStorage
function saveToLocalStorage(state: ReturnType<typeof store.getState>) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("persistantState", serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

// load string from localStarage and convert into an Object
// invalid output must be undefined
function loadFromLocalStorage(): any {
  try {
    const serialisedState = localStorage.getItem("persistantState");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState) as ReturnType<typeof store.getState>;
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

// SETTING UP THE STORE
export const store = configureStore({
  preloadedState: loadFromLocalStorage(),
  reducer: {
    user: userSlice,
    calendars: calendarsSlice
  }
})

store.subscribe(() => {
  saveToLocalStorage(store.getState());
  const savePrefs = async () => await api_prefs(store.getState().user);
  if(store.getState().user.loggedIn)
    savePrefs();
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector