import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import './App.css'
import { useAppSelector } from './redux/store';
import HomeLayout from './routes/Home/HomeLayout';
import NotFound from './routes/NotFound/NotFound';
import { useEffect } from 'react';
import { selectUser } from './redux/features/user/userSlice';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Calendar from './routes/Home/components/Calendar';
import YourData from './routes/Home/subroutes/YourData';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwyFlf8TB6gltVeMjVdGgqwhUkJDMXrqE",
  authDomain: "denispanda-510fb.firebaseapp.com",
  projectId: "denispanda",
  storageBucket: "denispanda.appspot.com",
  messagingSenderId: "476212405030",
  appId: "1:476212405030:web:ac0440465db2abb17257e7",
  measurementId: "G-PWWBWVB3JW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  const navigation = useNavigate();

  /** REDUX */
  const { preferences } = useAppSelector(selectUser);

  useEffect(() => {
    if(!preferences.darkMode) {
      document.documentElement.classList.add('light');
    }
    else {
      document.documentElement.classList.remove('light');
    }
  }, [preferences.darkMode])

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.setProperty('--primary-gradient', `var(--${preferences.colorMode}-gradient)`);
    document.getElementsByTagName('body')[0].style.setProperty('--primary-color', `var(--app-${preferences.colorMode})`);
  }, [preferences.colorMode])

  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index element={<Calendar />} />

        <Route index path="/calendars/:key?/*"/>

        <Route path="/yourdata" element={<YourData />} />
        
        <Route path="*"
          element={
            <Navigate to={"/"} replace></Navigate>
          }
        />
      </Route>
    </Routes>
  );
}

export default App
