import { AccordionProps, AccordionSummaryProps, styled } from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  zIndex:  10,
  width: "100%",
  backgroundColor: "transparent",
  overflow: "visible",
  '&:before': {
    display: "none"
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<FontAwesomeIcon icon={faCaretDown}/>}
    {...props}
  />
))(({ theme }) => ({
  zIndex:  10,

  position: "relative",

  marginLeft: "5%",
  marginRight: "5%",
  marginBottom: "6pt",

  borderRadius: "1rem",
  
  overflow: "visible",

  transition: `
    all 1.20s var(--app-timing-bezier),
    background-color 0.60s var(--app-timing-bezier)
  `,

  '&.Mui-expanded:before': {
    boxShadow: "var(--material-box-shadow-1)"
  },

  '&:before': {
    content: "''",
    position: "absolute",
    inset: "0",
    
    borderRadius: "1rem",

    backgroundColor: "var(--secondary-bg-color)",
    boxShadow: "var(--material-box-shadow-1)",

    transition: `
      all 1.20s var(--app-timing-bezier),
      background-color 0.30s var(--app-timing-bezier)
    `,
  },

  '&:hover:before, &:focus:before, &:focus-visible:before': {
    borderRadius: "0.5rem",
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'var(--primary-border-color)'
  },
  '& .MuiAccordionSummary-expandIconWrapper .Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {

    margin: 0,
    placeItems: "center",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  zIndex:  5,

  overflow: "visible",
  marginInline: "6pt",
  marginBottom: "6pt",
  
  padding: 0
}));

export { Accordion, AccordionSummary, AccordionDetails };