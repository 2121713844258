import { ButtonGroup } from "@mui/material";
import styles from "./YourData.module.css";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import CircleButton from "src/components/buttons/CircleButton";
import { api_deluser, api_getuser } from "src/api/functions";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { redux_user_actions, selectUser } from "src/redux/features/user/userSlice";
import { DPCalendar_User } from "types";

const RecursiveJSON = ({data, level = 0, ...props}: {data: Object, level?: number}) => {

  return(
    <ul
      style={{
        marginTop: level ? "4pt" : "0",
        paddingLeft: level ? "8pt" : "0",
        listStyleType: "none",
      }}
    >
      {
        Object.keys(data).map(
          (key, index) => {

            const value = Object.values(data)[index];

            if(typeof value !== 'object' && typeof value !== 'string') return;

            return(
              <li
                key={key}

                className={styles.recursiveJSONListItem}

                style={{
                  margin: level ? "0.25rem" : "0rem 0.25rem 0.25rem 0",
                  
                  border: "1px solid var(--primary-border-color)",
                  borderRadius: "0.5rem",

                  padding: "0.25rem"
                }}
              >
                <h3 style={{ margin: 0, fontSize: "1em" }}>
                  {key}
                </h3>
                {
                  (
                    typeof value === 'object' &&
                    !Array.isArray(value) &&
                    value !== null
                  ) ?
                  <RecursiveJSON data={value} level={level+1} /> :
                  <div
                    style={{
                      fontSize: "1em"
                    }}
                  >
                    {value}
                  </div>
                }
              </li>
            )
          }
        )
      }
    </ul>
  )
}

function YourData() {

  /** REACT ROUTER */
  const navigation = useNavigate();
  
  /** REDUX */
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  /** LOCAL STATE */
  const [ data, setData ] = useState<DPCalendar_User>();

  const [ dataLink, setDataLink] = useState<string>();


  useEffect(
    () => {

      const downloadData = async () => {
        const str = JSON.stringify(data);
        const bytes = new TextEncoder().encode(str);
        const blob = new Blob([bytes], {
            type: "application/json;charset=utf-8"
        });
        const href = await URL.createObjectURL(blob);
        setDataLink(() => href);
      }

      downloadData();
    }, [ data ]
  )

  return (
    <main className={styles.content}>

      <div className={styles.titleSection}>

        <CircleButton
          style={{
            marginRight: "2pt",

            height: "20pt",
            padding: "4pt",

            alignSelf: "center",
            justifyContent: "center",
          }}
          onClick={(e) => {
            navigation(-1);
          }}
        >
          <FontAwesomeIcon
            icon={faCircleArrowLeft}
            style={{
              maxHeight: "100%",
              color: "inherit",
            }}
          />
        </CircleButton>

        <h1>Your Personal Information</h1>
      </div>

      <div className={styles.body}>
        
        <h3 style={{ margin: 0, fontSize: "1em" }}>
          You have a right to your personal information.
        </h3>

        <p style={{ margin: 0, fontSize: "0.75em", fontFamily: "JetBrains Mono, monospace", fontStyle: "italic"}}>
          <br />
          We do not collect anything but what's necessary for the functionality of this app.<br />
          This data is safely stored and is <strong>NOT</strong> shared with anyone else.<br /><br />
        </p>

        <h3 style={{ margin: 0, fontSize: "1em"}}>
          Here are the actions you can do with your data:
        </h3>

        <div className={styles.buttonGroup}>

          <button

            disabled={Boolean(data)} 

            style={{
              pointerEvents: data ? "none" : "all",
              opacity: data ? 0.5 : 1
            }}

            onMouseOver={(e) => {
              e.currentTarget.style.setProperty("--active-color", "var(--primary-color)");
            }}

            onClick={() => {
              const downloadData = async () => {
                const result = await api_getuser(user);
                setData(result.data);
                return result;
              }
              downloadData();
            }}
          >
            View My Data
          </button>

          {
            <a href={dataLink} download={"Your Calendar+ Data.json"}
              style={{
                all: "unset",

                maxWidth: data ? "100%" : 0,
                opacity: data ? 1 : 0,
                
                transition: "all 0.30 var(--app-timing-bezier)",
              }}
            >
              <button
                onMouseOver={(e) => {
                  e.currentTarget.style.setProperty("--active-color", "var(--primary-color)");
                }}
              >
                Download
              </button>
            </a>
          }

          <button
            onMouseOver={(e) => {
              e.currentTarget.style.setProperty("--active-color", "red");
            }}
            onClick={() => {
              const deleteData = async () => {
                const result = await api_deluser(user);
                console.log(result);
                dispatch(redux_user_actions.clear());
                return result;
              }
              deleteData();
            }}
          >
            Delete My Account
          </button>
        </div>

        {
          data?.info &&
          <RecursiveJSON data={data}/>
          // <ul
          //   style={{
          //     marginTop: 0,
          //     paddingLeft: 0
          //   }}
          // >
          //   {
          //     JSON.stringify(data.info)
          //   }

          //   {
          //     Object.keys(data).map(
          //       (key, index) => (
          //         <li>
          //           <p>
          //             {key}: {JSON.stringify(Object.values(data)[index])}
          //           </p>
          //         </li>
          //       )
          //     )
          //   }
          // </ul>
        }

      </div>

    </main>
  );
}

export default YourData;
