import { useParams } from "react-router-dom";
import styles from "./Calendar.module.css"
import { useAppSelector } from "src/redux/store";
import { selectCalendars } from "src/redux/features/calendars/calendarsSlice";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { api_events } from "src/api/functions";
import { selectUser } from "src/redux/features/user/userSlice";
import { DPCalendar_EventInfo, GoogleCalendarEvents } from "types";


const LOG = false;

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function max(a: any, b: any) {
  return a > b ? a : b;
}

// function min(a: any, b: any) {
//   return a < b ? a : b;
// }

function logistic(x: number, L: number = 100, e: number = 1) {
  
  if(x <= 0) return 0;

  const den = 1 + Math.pow(Math.E, -(x-5));

  const res = L / den;

  if(Math.abs(res - L) < e) return L;

  return res;
}

function Calendar() {
  LOG && console.log('\n\nCALENDAR')
  
  /** REACT ROUTER */
  const { key } = useParams<{ key: string }>();

  /** REDUX */
  // const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const calendars = useAppSelector(selectCalendars);
  // const preferences = useAppSelector(selectPreferences);

  /** LOCAL STATE */
  const [events, setEvents] = useState<GoogleCalendarEvents>();
  const [eventsLoading, setEventsLoading] = useState<boolean>(true);
  
  const [calendarDimensions, setCalendarDimensions] = useState({
      w: 0,
      h: 0
  });

  const [timeColumnWidth] = useState(70);
  const [headerHeight] = useState(55);
  const [now, setNow] = useState<Date>(new Date());

  const [zoomBy, setZoomBy] = useState<(increment: number) => void>();

  /** LOCAL REFS */
  const [wrapperRef, setWrapperRef ] = useState<HTMLDivElement>();
  const wrapperRefCallback = useCallback(
    (node: HTMLDivElement) => {
      setWrapperRef(node);
    }, []);

  const [calendarRef, setCalendarRef] = useState<HTMLDivElement>();
  const calendarRefCallback = useCallback(
    (node: HTMLDivElement) => {
      setCalendarRef(node);
    }, []);

  const timeColumnRef = useRef<HTMLDivElement>(null);
  const zoomLevelRef = useRef<number>(50);

  const [ loadingIndicatorRef, setLoadingIndicatorRef ] = useState<HTMLDivElement>();
  const loadingIndicatorRefCallback = useCallback(
    (node: HTMLDivElement) => {
      setLoadingIndicatorRef(node);
    }, []);

  /** TIME UPDATER */
  useLayoutEffect(
    () => {
      const updateNow = () => {
        setNow(new Date(Date.now()));
      };
      updateNow();
      const interval = setInterval(updateNow, 1000 * 60);
      return () => {
        clearInterval(interval);
      };
    }, 
    []);

  /** RESIZING */
  useLayoutEffect(
    () => {
      if(!wrapperRef || !calendarRef) return;

      const resizeHandler = (entries: ResizeObserverEntry[]) => {

        // DEALING WITH WIDTH
        const calendarWidth = entries[0].contentRect.width;
        let cell_width = max(50, ((entries[0].contentRect.width - timeColumnWidth) / 7));
        wrapperRef?.style.setProperty('--cell-width', `${cell_width}px`);

        // SHORTENING DAY NAMES AT TOP
        if (cell_width < 100) {
          document.querySelectorAll(`.${styles.calendarHeaderDay}`).forEach(
            (day, i) => {
              day.innerHTML = DAYS[i].substring(0,3);
            }
          );
        } else {
          document.querySelectorAll(`.${styles.calendarHeaderDay}`).forEach(
            (day, i) => {
              day.innerHTML = DAYS[i];
            }
          );
        }

        // DEALING WITH HEIGHT
        const calendarHeight = entries[0].contentRect.height;

        const _zoomBy = (increment: number) => {
          let newZoomLevel = zoomLevelRef.current + increment;
  
          if (newZoomLevel > 100) newZoomLevel = 100;
          if (newZoomLevel < 0) newZoomLevel = 0;
  
          zoomLevelRef.current = newZoomLevel;
  
          const min_cell_height = max(calendarHeight / 24, 40);
          const max_cell_height = min_cell_height * 3;
  
          const cell_height_range = max_cell_height - min_cell_height;
  
          const cell_height = min_cell_height + ((newZoomLevel / 100) * cell_height_range);
  
          wrapperRef?.style.setProperty('--cell-height', `${cell_height}px`);
        }
        
        _zoomBy(0);
        setZoomBy(() => _zoomBy);

        setCalendarDimensions({
          w: calendarWidth,
          h: calendarHeight
        });
      };

      const resizeObserver = new ResizeObserver(resizeHandler);
      resizeObserver.observe(calendarRef);
      return () => {
        resizeObserver.disconnect();
      };
    }, 
    [wrapperRef, calendarRef, timeColumnWidth]);

  /** USER DATA RELATED EFFECTS */

  /* IF SPECIFIC KEY IS SPECIFIED */
  useEffect(
    () => {
      /** REFRESH EVENTS ON LOAD */
      const loadEvents = async () => {

        if(!key || !calendars?.items) return;

        const calendar = calendars.items?.find((cal) => cal.etag?.replace(/['"]+/g, '')===key);

        if(!calendar || !calendar.id) return;

        setEventsLoading(true);
        const { data: events } = await api_events(calendar.id as string, user.info);
        setEventsLoading(false);

        if(!events) return;

        setEvents(events);
      };
      key && loadEvents();
    }, 
    [key, calendars.items, user.info]);
  
  /* IF USER CHANGES */
  useEffect(
    () => {
      if(!key || !user.loggedIn || !calendars || !calendars.items) setEvents({});
    },
    [key, user, calendars]);

  /** COMPONENTS */
  const CalendarGrid = useMemo(() => {
    LOG && console.log("CalendarGrid");

    let rows = [];
    for(let i = 0; i < 24; i++) {
      rows.push(
        <div
          key={i+51}
          className={`${styles.calendarRow}`}
          style={{
            width: "100%",
            height: `var(--cell-height)`,
            borderBottom: `1px solid ${i < 23 ? 'var(--primary-border-color)' : 'transparent'}`
          }}
        >
        </div>
      )
    }

    let cols = [
      <div
        key={0}
        className={`${styles.calendarColumn}`}
        style={{
          minWidth: `${timeColumnWidth}px`,
          width: `${timeColumnWidth}px`,
          borderRight: `1px solid transparent`,
        }}
      >
      </div>
    ];
    for(let i = 0; i < 7; i++) {
      cols.push(
        <div
          key={i+1}
          className={`${styles.calendarColumn}`}
          style={{
            height: "100%",
            minWidth: `var(--cell-width)`,
            width: `var(--cell-width)`,
            borderRight: `1px solid ${i < 6 ? 'var(--primary-border-color)' : 'transparent'}`,
          }}
        >
        </div>
      )
    }

    return (
      <div
        className={`${styles.calendarGrid}`}
        style={{
        }}
      >
        <div
          style={{
            position: "relative",
            zIndex: 1,
            width: "100%"
          }}
        >
          {rows}
        </div>
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            height: "100%",
            display: "flex"
          }}
        >
          {cols}
        </div>
      </div>
    )
  }, [timeColumnWidth]);

  const CalendarEvents = useMemo(() => {
    LOG && console.log("CalendarEvents");

    if(!events || !events.items || !events.items[0]) return <></>;

    const renderedEvents: {
      indentX: number,
      indentY: number,
      info: DPCalendar_EventInfo
    }[] = [];

    let latestEvent: {
      indentX: number,
      indentY: number,
      info?: DPCalendar_EventInfo
    } = {
      indentX: 0,
      indentY: 0,
    }
    
    events.items.forEach(
      (item, index, arr) => {

        // EVENT ID
        const id = item.id as string;

        // EVENT NAME
        const name = item.summary as string;
        
        // EVENT ALL DAY ?
        const allDay = Boolean(item.start?.date);

        // EVENT TIME
        let start = new Date((allDay ? item.start?.date : (item.start?.dateTime || "")) as string);
        let end = new Date((allDay ? item.end?.date : (item.end?.dateTime || "")) as string);
        
        start = new Date(
          allDay ? start.getUTCFullYear() : start.getFullYear(),
          allDay ? start.getUTCMonth(): start.getMonth(),
          allDay ? start.getUTCDate(): start.getDate(),
          allDay ? start.getUTCHours(): start.getHours(),
          allDay ? start.getUTCMinutes(): start.getMinutes(),
          allDay ? start.getUTCSeconds(): start.getSeconds()
        );
        end = new Date(
          allDay ? end.getUTCFullYear(): end.getFullYear(),
          allDay ? end.getUTCMonth(): end.getMonth(),
          allDay ? end.getUTCDate(): end.getDate(),
          allDay ? end.getUTCHours(): end.getHours(),
          allDay ? end.getUTCMinutes(): end.getMinutes(),
          allDay ? end.getUTCSeconds(): end.getSeconds()
        );

        // TODO: MULTI-DAY EVENT SUPPORT
        if(start.getDate() !== end.getDate()) return;

        const durationMillis = new Date(end.getTime() - start.getTime());
        const durationHours = durationMillis.getUTCHours()*1.0 + (durationMillis.getUTCMinutes()/60);

        const timezoneOffset = new Date(start).getTimezoneOffset();

        const location = item.location as string;

        let eventInfo: DPCalendar_EventInfo = {
          id: id,
          name: name,
          
          allDay: allDay,

          start: start,
          end: end,

          duration: durationHours,

          timezoneOffset: timezoneOffset,

          location: location,
        };

        
        // COLLISION CHECK
        let indentX: number = 0;
        let indentY: number = 0;
        if(index > 0 && renderedEvents.length > 0) {

          let prev = renderedEvents[renderedEvents.length-1];

          // If this event starts before latest one ends, indent according to latest
          if(latestEvent.info && start.getTime() < latestEvent.info?.end.getTime()) {
            indentX = latestEvent.indentX + 1;
          }

          // If this event starts at the same time as previous one, adjust order by duration
          if(start.getTime() === prev.info.start.getTime()) {
            indentY = prev.indentY + 1;

            // If this event's duration is longer, place this event before previous event
            if(durationHours > prev.info.duration) {
              renderedEvents.pop();

              renderedEvents.push({
                indentX: prev.indentX,
                indentY: prev.indentY,
                info: eventInfo
              });
              
              // Update latest event if this event is later than latest event
              if(!latestEvent.info || (latestEvent.info && latestEvent.info.end.getTime() < eventInfo.end.getTime())) {
                latestEvent = {
                  indentX: prev.indentX,
                  indentY: prev.indentY,
                  info: eventInfo
                }
              }

              eventInfo = prev.info;
            }
          }

          // If this event starts before the previous one in list ends, indent according to previous
          if(indentX === prev.indentX && start.getTime() < prev.info.end.getTime()) {
            indentX = prev.indentX + 1;
          }
        }

        // Update latest event if this event is later
        if(!latestEvent.info || (latestEvent.info && latestEvent.info.end.getTime() < eventInfo.end.getTime())) {
          latestEvent = {
            indentX: indentX,
            indentY: indentY,
            info: eventInfo
          }
        }

        renderedEvents.push({
          indentX: indentX,
          indentY: indentY,
          info: eventInfo
        });    
      }
    );

    return(
      <div
        className={styles.calendarEvents}
        style={{
          top: 0,
          left: `${timeColumnWidth}px`,
          right: 0,
          bottom: 0,
        }}
      >
        {renderedEvents.map((e, i, a) => {

          const { indentX, indentY, info: eventInfo } = e;
          
          // EVENT PLACEMENT ON CALENDAR
          const style: React.CSSProperties = {};

          style.left = `
            calc(
              var(--cell-width) * 
              ${eventInfo.start.getDay()}
            )
          `;
          style.top = `
            calc(
              var(--cell-height) * ${eventInfo.start.getHours()}
              + var(--cell-height) * ${eventInfo.start.getMinutes() / 60}
            )
          `;
          style.width = `
            calc(
              var(--cell-width) - 
              1px
            )
          `;

          style.minHeight = 
            eventInfo.allDay?
            '25px' :
            `
              calc(
                var(--cell-height)
                * ${eventInfo.duration}
                - 1px
              )
            `
          ;
          
          style.height = 
            eventInfo.allDay?
            '25px' :
            `
              calc(
                var(--cell-height)
                * ${eventInfo.duration}
                - 1px
              )
            `
          ;

          style.paddingLeft = `${
            10
          }px`;
          style.paddingRight = `${
            10
          }px`;
          style.paddingTop = `${
            10
          }px`;

          style.paddingBottom = `${
            eventInfo.allDay?
            10 :
            0
          }px`
          
          style.opacity = `${
            eventInfo.allDay?
            0.70 :
            1
          }`

          if(indentX) {
            let offsetX = `
              calc(
                var(--cell-width)
                / 5
                * ${indentX ** 1.3}
              )
            `;

            style.marginLeft = `
                ${offsetX}
            `;
            style.width = `
              calc(
                (
                  var(--cell-width)
                  - 1px
                )
                - 
                ${offsetX}
              )
            `
          }

          if(indentY) {

          }

          return(
            <div
              key={`${eventInfo.name + eventInfo.start.getTime()} ${i}`}
  
              title={`${eventInfo.name}`}

              tabIndex={0}
  
              className={`${styles.calendarEvent} ${indentX || indentY ? styles.calendarEventIndented:''}`}
              style={{
                position: 'absolute',
                ...style
              }}
            >
              <div
                className={styles.calendarEventHeader}
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {
                  // indentX+' '+indentY+' '+i
                  eventInfo.name 
                }
              </div>
              
              { 
                !eventInfo.allDay &&
                
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {`${
                    (eventInfo.start.getHours()%12) || '12'
                  }:${
                    eventInfo.start.getMinutes() >= 10 ?
                    eventInfo.start.getMinutes() :
                    "0"+eventInfo.start.getMinutes()
                  }${
                    (eventInfo.start.getHours() < 12) ?
                    (eventInfo.end.getHours() >= 12) ? 'am' : '' :
                    (eventInfo.end.getHours() < 12) ? 'pm' : ''
                  }`}
                  {` - `}
                  {`${
                    (eventInfo.end.getHours()%12) || '12'
                  }:${
                    eventInfo.end.getMinutes() >= 10 ?
                    eventInfo.end.getMinutes() :
                    "0"+eventInfo.end.getMinutes()
                  }${
                    (eventInfo.end.getHours() / 12 < 1) ?
                    'am' :
                    'pm'
                  }`}
                </div>
              }
  
              <div
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {
                  eventInfo.location?.substring(0, eventInfo.location.indexOf(','))
                }
              </div>
            </div>
          )
        })}
      </div>
    )
  }, [timeColumnWidth, events]);

  const CalendarTimeIndicator = useMemo(() => {
    LOG && console.log("CalendarTimeIndicator");
    return (
      <div
        className={styles.calendarTimeIndicator}
        style={{
          top: `
            calc(
              var(--cell-height) * ${now.getHours()} +
              var(--cell-height) / 60 * ${now.getMinutes()}
            )
          `,
          width: `100%`,
          height: `2px`,
        }}
      >
      </div>
    )
  }, [now]);

  const CalendarTimeColumn = useMemo(() => {
    LOG && console.log("CalendarTimeColumn");
    let cells = [];
    for(let i = 0; i < 24; i++) {
      cells.push(
        <div
          key={i}
          className={`${styles.calendarTimeColumnCell}`}
          style={{
            height: `var(--cell-height)`
          }}
        >
          {i < 23 ? (i+1) % 12 || '12' : ''}
          {i < 23 ? ((i+1) / 12 >= 1) ? 'PM' : 'AM' : ''}
        </div>
      )
    }
    return(
      <div
        ref={timeColumnRef}
        className={`${styles.calendarTimeColumn}`}
        style={{
          paddingTop: `${headerHeight}px`,
          width: `${timeColumnWidth}px`,
        }}
        tabIndex={-1}
      >
        {cells}
      </div>
    )
  }, [headerHeight, timeColumnWidth]);

  const CalendarHeaderBar = useMemo(() => {
    LOG && console.log("CalendarHeaderBar");

    const lastSunday = new Date(now.getFullYear(), now.getMonth(), now.getDate()-now.getDay());

    let cells = [
      <div
        key={0}
        className={styles.calendarTimeZone}
        style={{
          minWidth: `${timeColumnWidth}px`,
          width: `${timeColumnWidth}px`,
        }}
      >
        {
          "GMT"+(new Date().getTimezoneOffset() / -60)
        }
      </div>
    ];

    for(let i = 0; i < 7; i++) {

      const day = new Date(0);
      day.setUTCDate(2);

      const date = new Date(lastSunday.getTime() + (day.getTime() * i));
      const today = date.getDate() === now.getDate();

      cells.push(
        <div
          key={i+1}
          className={`${styles.calendarHeader}`}
          style={{
            minWidth: `var(--cell-width)`,
            width: `var(--cell-width)`,
            height: '100%',
          }}
        >
          <div 
            className={`${styles.calendarHeaderDay}`}
            style={{
              
              ...(
                today &&
                {
                  color: 'red',
                  fontWeight: 900
                }
              )
            }}
          >
            {DAYS[i]}
          </div>
          <div 
            className={`${styles.calendarHeaderDate}`}
            style={{
              
              ...(
                today &&
                {
                  color: 'red',
                  fontWeight: 900
                }
              )
            }}
          >
            {date.getDate()}
          </div>
        </div>
      )
    }

    return (
      <div
        className={styles.calendarHeaderBarWrapper}
      >
        <div
          className={styles.calendarHeaderBar}
          style={{
            height: `${headerHeight}px`,
            paddingTop: `${headerHeight/10}px`,
            paddingBottom: `${headerHeight/10}px`,
          }}
        >
          {cells}
        </div>
      </div>
    )
  }, [headerHeight, timeColumnWidth, now]);

  const CalendarZoomSlider = useMemo(() => {
    LOG && console.log("CalendarZoomSlider");

    const CalendarZoomButton = (
      {
        zoomFunction, 
        children, 
        ...props
      } : 
      {
        zoomFunction: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void, 
        [x:string]: any
      }
    ) => {
      return (
        <button
          {...props}
          onClick={(e) => {
            zoomFunction(e);
          }}
          onMouseLeave={(e) => {
            e.currentTarget.blur();
          }}
        >
          {children}
        </button>
      )
    }

    const zoomIn = () => {
      zoomBy?.(10);
    }
    const zoomOut = () => {
      zoomBy?.(-10);
    }
    
    return(
      <div
        className={`${styles.calendarZoomSliderWrapper}`}
      >
        <CalendarZoomButton
          zoomFunction={zoomIn}
        >
          +
        </CalendarZoomButton>
        <CalendarZoomButton
          zoomFunction={zoomOut}
        >
          -
        </CalendarZoomButton>
      </div>
    )
  }, [zoomBy]);


  const loadingIndicatorWasLoading = useRef<boolean>();
  const loadingIndicatorAnimationID = useRef<number>(0);
  const loadingIndicatorAnimationStart = useRef<number>(0);

  const CalendarLoadingIndicator = useMemo(() => {
    LOG && console.log("CalendarLoadingIndicator");

    // Restarts loading animation
    if(eventsLoading && (!loadingIndicatorWasLoading.current || loadingIndicatorWasLoading.current === undefined)) loadingIndicatorAnimationStart.current = 0;

    loadingIndicatorWasLoading.current = eventsLoading;

    // Cancel any previous animation currently going on
    window.cancelAnimationFrame(loadingIndicatorAnimationID.current);

    // Animating this with JS, not CSS.
    const element = loadingIndicatorRef;

    function load(timeStamp: number) {
      if (loadingIndicatorAnimationStart.current === 0) {
        loadingIndicatorAnimationStart.current = timeStamp;
      }

      const elapsed = timeStamp - loadingIndicatorAnimationStart.current;

      const progress = 
          eventsLoading ?
          logistic(elapsed / 250, 75, 0.1):
          logistic(elapsed / 100, 100);

      if (element) {
        element.style.width = `${progress}%`
      }
      if(progress < 100) {
        loadingIndicatorAnimationID.current = window.requestAnimationFrame(load);
      }
    }

    if(key && loadingIndicatorRef) loadingIndicatorAnimationID.current = window.requestAnimationFrame(load);

    return (
      <div
        style={{
          position: "absolute",
          zIndex: 40,
          inset: 0,
          backdropFilter: `${(!key || eventsLoading) ? "blur(10px) opacity(1)" : "blur(10px) opacity(0)"}`,
          transition: "backdrop-filter 1s var(--app-timing-bezier)",

          pointerEvents: (user.loggedIn && key && !eventsLoading) ? "none" : "auto",
        }}
      >
        <div
          id="CalendarLoadingIndicator"
          ref={loadingIndicatorRefCallback}
          className={styles.calendarLoadingIndicator}
          style={{
            position: "absolute",
            zIndex: 100,

            top: `${headerHeight}px`,
            left: 0,

            height: "2px",

            background: "var(--primary-gradient)",
          }}
        ></div>
      </div>
    );
  }, [key, headerHeight, loadingIndicatorRef, loadingIndicatorRefCallback, eventsLoading, user.loggedIn]);

  return (
    <div
      ref={wrapperRefCallback}
      className={styles.wrapper}
    >
      <div
        ref={calendarRefCallback}
        className={`${styles.calendar}`}
        style={{
          top: `${headerHeight}px`,
        }}
        onScroll={
          (e) => {
            if(!calendarRef || !timeColumnRef.current) return;
            timeColumnRef.current.scrollTop = calendarRef.scrollTop;
          }
        }
      >
        {CalendarGrid}
        {CalendarEvents}
        {CalendarTimeIndicator}
      </div>
      {CalendarTimeColumn}
      {CalendarHeaderBar}
      {CalendarZoomSlider}
      {CalendarLoadingIndicator}
    </div>
  );
}

export default Calendar
