import axios from "axios";
import { DPCalendar_APIResponse, DPCalendar_User, GoogleCalendarEvents, GoogleCalendarList, GoogleUserInfo } from "../../types";

let API_HOST = `https://api.calendar.denispanda.com/api`;
if (window.location.hostname === 'localhost') {
  console.log(`Running on localhost, switching API hostname...`)
  API_HOST = `http://localhost:4000/api`;
}

export function api_loginauth(code: string) {
  return axios
    .post<DPCalendar_APIResponse<Omit<DPCalendar_User, 'loggedIn'>>>(
      `${API_HOST}/loginauth`,
      {
        code
      },
      {
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      console.log("\n\nERROR LOGGING IN.")
      console.log(error.message);
      return {} as DPCalendar_APIResponse<Omit<DPCalendar_User, 'loggedIn'>>;
    });
}

export async function api_calendars(userinfo: GoogleUserInfo) {
  return axios
    .post<DPCalendar_APIResponse<GoogleCalendarList>>(
      `${API_HOST}/calendars`,
      {
        id: userinfo.id
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      console.log("\n\nERROR GETTING CALENDAR LIST.")
      console.log(error.message);
      return {} as DPCalendar_APIResponse<GoogleCalendarList>;
    })
}

export function api_events(calendarId: string, userinfo: GoogleUserInfo) {
  return axios
    .post<DPCalendar_APIResponse<GoogleCalendarEvents>>(
      `${API_HOST}/events`,
      {
        calendarId,
        id: userinfo.id
      },
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      console.log("ERROR GETTING CALENDAR EVENTS.")
      console.log(error.message);
      return {} as DPCalendar_APIResponse<GoogleCalendarEvents>;
    })
}

export function api_prefs(user: DPCalendar_User) {
  return axios
    .post<DPCalendar_APIResponse<GoogleCalendarEvents>>(
      `${API_HOST}/prefs`,
      {
        id: user.info.id,
        preferences: user.preferences
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      console.log("ERROR SAVING PREFERENCES.")
      console.log(error.message);
      return {} as DPCalendar_APIResponse<GoogleCalendarEvents>;
    })
}

export function api_getuser(user: DPCalendar_User) {
  return axios
    .post<DPCalendar_APIResponse<DPCalendar_User>>(
      `${API_HOST}/getuser`,
      {
        id: user.info.id
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      console.log("ERROR DELETING USER.")
      console.log(error.message);
      return {} as DPCalendar_APIResponse<DPCalendar_User>;
    })
}

export function api_deluser(user: DPCalendar_User) {
  return axios
    .post<DPCalendar_APIResponse<any>>(
      `${API_HOST}/deluser`,
      {
        id: user.info.id
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      console.log("ERROR DELETING USER.")
      console.log(error.message);
      return {} as DPCalendar_APIResponse<any>;
    })
}