import { Link, useLocation, useParams } from "react-router-dom";
import styles from "./CalendarSidebar.module.css";
import { selectCalendars } from "src/redux/features/calendars/calendarsSlice";
import { selectUser } from "src/redux/features/user/userSlice";
import { useAppSelector } from "src/redux/store";
import { useEffect, useState } from "react";
import { GoogleCalendarListEntry } from "types";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "./CalendarSidebarAccordion";
import CalendarLoginButton from "../CalendarLogin/CalendarLoginButton";

const LOG = false;

function CalendarSidebar() {
  LOG && console.log("CalendarSidebar", Math.random());
  
  /** REACT ROUTER */
  const { key } = useParams<{ key: string }>();
  const location = useLocation();

  /** REDUX */
  const { loggedIn, info, preferences } = useAppSelector(selectUser);
  const calendars = useAppSelector(selectCalendars);

  /** LOCAL STATES */
  const [yourCals, setYourCals] = useState<GoogleCalendarListEntry[]>([]);
  const [sharedCals, setSharedCals] = useState<GoogleCalendarListEntry[]>([]);

  const [expanded, setExpanded] = useState<{[x:string]: boolean}>({});
  const handleAccordions = (title: string, newExp: boolean) => {
    setExpanded(
      (prev) => {
        return {
          ...prev,
          [title]: newExp
        }
      }
    )
  }

  useEffect(
    () => {
      if(!loggedIn) setExpanded({});
    },
    [loggedIn]
  );

  useEffect(
    () => {

      let _yourCals: GoogleCalendarListEntry[] = [];
      let _sharedCals: GoogleCalendarListEntry[] = [];

      if(!calendars?.items) {
        return;
      }

      _yourCals = calendars.items.filter((cal) => cal.accessRole==='owner');
      _sharedCals = calendars.items.filter((cal) => cal.accessRole!=='owner');

      setYourCals(_yourCals);
      setSharedCals(_sharedCals);
    },
    [calendars.items]
  );


  // PERSISTENT ACCORDIONS
  useEffect(
    () => {
      try {
        const serialisedState = localStorage.getItem("persistantSidebarAccordions");
        if (serialisedState === null) return;
        setExpanded(JSON.parse(serialisedState));
      } catch (e) {
        console.warn(e);
        return;
      }
    },
    []
  )
  useEffect(
    () => {
      try {
        const serialisedState = JSON.stringify(expanded);
        localStorage.setItem("persistantSidebarAccordions", serialisedState);
      } catch (e) {
        console.warn(e);
      }
    },
    [expanded]
  )

  return (
    <div className={styles.sidebar}>
      {info.picture && (
        <div className={styles.pictureWrapper}>
          <div
            className={styles.picture}
            style={{
              backgroundImage: `url(${info.picture})`,
            }}
          ></div>
        </div>
      )}

      <div className={styles.colorBlobWrapper}>
        <div className={styles.colorBlob1} />
      </div>

      <div className={styles.sidebarInner}>
        {!loggedIn &&
          <div
            style={{
              zIndex: 10
            }}
          >
            <CalendarLoginButton />
          </div>
        }
        {loggedIn && yourCals.length && (
          <Accordion
            expanded={expanded["your"] === undefined ? false : expanded["your"]}
            onChange={(event, newExp) => {
              handleAccordions("your", newExp);
            }}
          >
            <AccordionSummary>
              <div className={styles.sidebarHeading}>Your Calendars</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.sidebarTabGroup}>
                {yourCals.length ? (
                  yourCals.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={`/calendars/${item.etag?.replace(/['"]+/g, "")}`}
                        className={`${styles.sidebarTab} ${
                          item.etag?.replace(/['"]+/g, "") === key
                            ? styles.selected
                            : ""
                        }`}
                        title={
                          (item.summaryOverride as string | undefined) ||
                          (item.summary as string | undefined)
                        }
                        onMouseLeave={(e) => {
                          e.currentTarget.blur();
                        }}
                      >
                        <div>
                          <div
                            onMouseEnter={(e) => {
                              if (!e.currentTarget.parentElement) return;
                              const overflow =
                                e.currentTarget.offsetWidth <
                                e.currentTarget.scrollWidth;
                              if (overflow)
                                e.currentTarget.classList.add(
                                  styles.scrollText
                                );
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.classList.remove(
                                styles.scrollText
                              );
                            }}
                          >
                            {item.summaryOverride || item.summary}
                          </div>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <>
                    <div className={styles.sidebarTab}></div>
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        )}

        {loggedIn && sharedCals.length && (
          <Accordion
            expanded={
              expanded["shared"] === undefined ? false : expanded["shared"]
            }
            onChange={(event, newExp) => {
              handleAccordions("shared", newExp);
            }}
          >
            <AccordionSummary>
              <div className={styles.sidebarHeading}>Other Calendars</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.sidebarTabGroup}>
                {sharedCals.length ? (
                  sharedCals.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={`/calendars/${item.etag?.replace(/['"]+/g, "")}`}
                        className={`${styles.sidebarTab} ${
                          item.etag?.replace(/['"]+/g, "") === key
                            ? styles.selected
                            : ""
                        }`}
                        title={
                          (item.summaryOverride as string | undefined) ||
                          (item.summary as string | undefined)
                        }
                        onMouseLeave={(e) => {
                        }}
                      >
                        <div>
                          <div
                            onMouseEnter={(e) => {
                              if (!e.currentTarget.parentElement) return;
                              const overflow =
                                e.currentTarget.offsetWidth <
                                e.currentTarget.scrollWidth;
                              if (overflow)
                                e.currentTarget.classList.add(
                                  styles.scrollText
                                );
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.blur();
                              e.currentTarget.classList.remove(
                                styles.scrollText
                              );
                            }}
                          >
                            {item.summaryOverride || item.summary}
                          </div>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <>
                    <div className={styles.sidebarTab}></div>
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        )}

        {/* SPACER */}
        <div
          style={{
            marginTop: "auto",
          }}
        ></div>

        <div
          className={`${styles.sidebarHeading} ${
            preferences.darkMode ? styles.sidebarHeadingDark : ""
          }`}
          style={{
            marginBottom: "0",
            fontStyle: "italic",
            fontWeight: "400",
            textTransform: "lowercase",
            userSelect: "none",
            MozUserSelect: "none",
          }}
        >
          v1.23.09.09
        </div>

        <div
          className={styles.linkGroup}
          style={{
            marginTop: "5pt",
            marginInline: "10pt",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Link 
            download={"Calendar+ | Privacy Policy"} 
            to={'/privacy.pdf'}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </Link>
          <Link
            style={{
              marginRight: "0pt",
              pointerEvents: loggedIn ? "auto" : "none",
              ...(!loggedIn && { color: "var(--primary-border-color)" }),
            }}
            to={'/yourdata'}
          >
            Personal Information
          </Link>
        </div>
      </div>
    </div>
  );
}

export default React.memo(CalendarSidebar);
