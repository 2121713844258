import { Link, LinkProps } from 'react-router-dom';
import styles from './DPButton.module.css'

type DPButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export function DPButton({
  children,
  className,
  style,
  ...props
}: DPButtonProps) {

  return(
    <button
      {...props}
      className={`${styles.button} ${className}`}
      style={{
        ...style
      }}
    >
      <div className={styles.buttontext}>
        {children}
      </div>
      <div className={styles.buttoninner}></div>
      <div className={styles.buttontextabsolute}>
        {children}
      </div>
    </button>
  )
}

type DPButtonLinkProps = LinkProps & React.RefAttributes<HTMLAnchorElement>;

export function DPButtonLink({
  children,
  className,
  style,
  ...props
}: DPButtonLinkProps) {

  return(
    <Link
      {...props}
      className={`${styles.button} ${className}`}
      style={{
        ...style
      }}
    >
      <div className={styles.buttontext}>
        {children}
      </div>
      <div className={styles.buttoninner}></div>
      <div className={styles.buttontextabsolute}>
        {children}
      </div>
    </Link>
  )
}