import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./CalendarSettings.module.css";
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faGear,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useState } from "react";

import { Popper } from "@mui/base/Popper";
import { ClickAwayListener, Fade, Grow } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { api_loginauth, api_calendars } from "src/api/functions";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { redux_calendar_actions } from "src/redux/features/calendars/calendarsSlice";
import { selectUser, redux_user_actions } from "src/redux/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import CircleButton from "src/components/buttons/CircleButton";

const LOG = false;

function CalendarSettings() {
  LOG && console.log("CalendarSettings");

  /** REACT ROUTER */
  const navigate = useNavigate();
  const location = useLocation();

  /** REDUX */
  const dispatch = useAppDispatch();
  const { loggedIn, info, preferences } = useAppSelector(selectUser);

  /** LOCAL STATES */
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popper, setPopper] = useState<null | HTMLDivElement>(null);

  const toggleSettings = () => {
    setOpen((old) => !old);
  };

  const setColorMode = (color: typeof preferences.colorMode = "orange") => {
    dispatch(redux_user_actions.setColorMode(color));
  };

  const ColorPicker = useCallback(
    ({ color = "orange" }: { color: typeof preferences.colorMode }) => {
      return (
        <button
          className={`
            ${styles.colorPicker}
            ${preferences.colorMode === color ? styles.focused : ""}
          `}
          style={{
            backgroundImage: `var(--${color}-gradient)`,
          }}
          onClick={() => {
            setColorMode(color);
          }}
        />
      );
    },
    [preferences.colorMode]
  );

  const toggleDarkMode = () => {
    dispatch(redux_user_actions.toggleDarkMode());
  };

  return (
    <>
      <CircleButton
        type="button"
        ref={setAnchorEl}
        onClick={(e) => toggleSettings()}
        focused={open}
      >
        <FontAwesomeIcon
          icon={faGear}
          className={`${styles.gear}`}
          style={{
            opacity: !open ? 1 : 0,
          }}
        />
        <FontAwesomeIcon
          icon={faXmark}
          className={`${styles.gear}`}
          style={{
            position: "absolute",
            inset: 0,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            opacity: open ? 1 : 0,
            animation: "none",
          }}
        />
      </CircleButton>

      <Popper
        id={open ? "settings-popper" : undefined}
        open={open}
        placement="bottom-end"
        anchorEl={anchorEl}
        transition={true}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            in={open}
            style={{ transformOrigin: "top right" }}
            timeout={100}
            easing={"cubic-bezier(0.4, 0, 0.6, 1)"}
          >
            
            <div
              className={`${styles.settingsWrapper}`}
              onMouseLeave={(e) => {
                popper?.classList.remove(styles.focused);
              }}
            >
              <ClickAwayListener
                onClickAway={
                  () => {
                    setOpen(false);
                  }
                }
              >
                <div
                  ref={setPopper}
                  className={`${styles.settings} ${open && styles.focused}`}
                >
                  {/* THEME TOGGLER */}
                  <div className={`${styles.settingsHeading}`}>COLOR</div>
                  <div className={styles.colorPickerWrapper}>
                    <ColorPicker color={"orange"} />
                    <ColorPicker color={"green"} />
                    <ColorPicker color={"pink"} />
                  </div>

                  {/* DARK MODE TOGGLER */}
                  <div className={`${styles.settingsHeading}`}>
                    {preferences.darkMode ? "DARK" : "LIGHT"}
                  </div>
                  <button
                    onClick={toggleDarkMode}
                    className={styles.toggleWrapper}
                  >
                    <input
                      checked={!preferences.darkMode}
                      onChange={toggleDarkMode}
                      type="checkbox"
                      name="toggle1"
                      id="toggle1"
                    />
                    <label
                      aria-label="Toggle Dark Mode"
                      htmlFor="toggle1"
                    ></label>
                  </button>
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>

    </>
  );
}

export default React.memo(CalendarSettings);
