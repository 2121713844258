import { DPCalendar_User } from '../../../../types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: DPCalendar_User = {
  loggedIn: false,
  info: {},
  preferences: {
    colorMode: 'orange',
    darkMode: window.matchMedia('(prefers-color-scheme: light)').matches
  }
}
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    load: (state, action: PayloadAction<Partial<DPCalendar_User>>) => {
      // console.log(`redux redux_user_load action: ${JSON.stringify(action)}`)
      state.loggedIn = action.payload.loggedIn || state.loggedIn;
      state.info = action.payload.info || state.info;
      state.preferences = action.payload.preferences || state.preferences;
    },
    clear: (state) => {
      // console.log(`redux redux_user_clear action: ${JSON.stringify(state)}`)
      state.loggedIn = initialState.loggedIn;
      state.info = initialState.info;
      // state.preferences = initialState.preferences;
    },
    toggleDarkMode: (state) => {
      state.preferences.darkMode = !state.preferences.darkMode
    },
    setColorMode: (state, action: PayloadAction<typeof state.preferences.colorMode>) => {
      state.preferences.colorMode = action.payload;
    }
  }
});

export const selectUser = (state: { user: typeof initialState; }): DPCalendar_User => state.user;

export const redux_user_actions = userSlice.actions

export default userSlice.reducer