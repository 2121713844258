import { useEffect } from "react";
import styles from "./HomeLayout.module.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "src/redux/store";
import { selectUser } from "src/redux/features/user/userSlice";
import CalendarTitle from "./components/CalendarTitle";
import CalendarSidebar from "./components/CalendarSidebar";
import CalendarSettings from "./components/CalendarSettings";
import Calendar from "./components/Calendar";
import CalendarLogin from "./components/CalendarLogin";

const LOG = false;

function HomeLayout() {
  LOG && console.log("HomeLayout");

  /** REACT ROUTER */
  const navigate = useNavigate();
  const location = useLocation();

  /** REDUX */
  const user = useAppSelector(selectUser);
  const { loggedIn, info } = user;

  useEffect(() => {
    if (location.pathname !== "/") {

      // If not logged in, login
      if(!user.loggedIn) {
        navigate("/", {
          replace: true,
          state: {
            from: location.pathname,
          },
        });
      }
    }
    
  }, [location, user, navigate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.topbar}>
        <div className={styles.topbarWrapper}>
          <CalendarTitle />

          <div
            style={{
              flex: 3,
            }}
          ></div>

          <CalendarLogin />
          
          <div
            style={{
              width: "6pt",
            }}
          ></div>

          <CalendarSettings />
        </div>
      </div>

      <div className={styles.content}>
        <div id="content" className={styles.contentWrap}>
          <CalendarSidebar />
          <div className={styles.outlet}>
            <Calendar />
            {
              location.pathname.startsWith("/yourdata") &&
              <div
                style={{
                  position: "absolute",
                  zIndex: 100,
                  inset: 0,
                  backgroundColor: "transparent",
                  backdropFilter: "blur(25px)",
                }}
              >
                <Outlet />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeLayout;
