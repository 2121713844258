import { useGoogleLogin } from "@react-oauth/google";
import { useLocation, useNavigate } from "react-router-dom";
import { api_calendars, api_loginauth } from "src/api/functions";
import { redux_calendar_actions } from "src/redux/features/calendars/calendarsSlice";
import { redux_user_actions, selectUser } from "src/redux/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import styles from "./CalendarLoginButton.module.css";


function CalendarLoginButton() {

  /** REACT ROUTER */
  const navigate = useNavigate();
  const location = useLocation();

  /** REDUX */
  const dispatch = useAppDispatch();
  const { preferences } = useAppSelector(selectUser);
  
  const _loginAuthcode = useGoogleLogin({
    redirect_uri: "denispanda.com",
    onSuccess: async ({ code }) => {
      const { data: user } = await api_loginauth(code);
      if (!user) return;
      dispatch(
        redux_user_actions.load({
          loggedIn: true,
          info: user.info,
          preferences: user.preferences,
        })
      );

      const { data: calendars } = await api_calendars(user.info);
      if (!calendars) return;
      dispatch(redux_calendar_actions.load(calendars));
      navigate(location.state?.from || "/calendars");
    },
    scope: "openid email profile https://www.googleapis.com/auth/calendar",
    flow: "auth-code",
    select_account: true,
  });

  return(
    <button
      className={styles.loginWithGoogleImage}
      onMouseLeave={(e) => {
        e.currentTarget.blur();
      }}
      style={{
        marginTop: "6pt",
        maxWidth: "75%",
        margin: 0,
        padding: 0,

        maxHeight: "min-content",
      }}
      onClick={(e) => {
        _loginAuthcode();
      }}
    >
      <img
        style={{
          maxWidth: "100%",
        }}
        src={require(`../../../../assets/google_signin_buttons/web/2x/btn_google_signin_${
          preferences.darkMode ? "dark" : "light"
        }_normal_web@2x.png`)}
        srcSet={`
          ${require(`../../../../assets/google_signin_buttons/web/1x/btn_google_signin_${
            preferences.darkMode ? "dark" : "light"
          }_normal_web.png`)} 191w,
          ${require(`../../../../assets/google_signin_buttons/web/2x/btn_google_signin_${
            preferences.darkMode ? "dark" : "light"
          }_normal_web@2x.png`)} 382w
        `}
      ></img>
    </button>
  )
}

export default CalendarLoginButton;