import { useEffect, useState } from 'react';
import styles from './CalendarTitle.module.css'
import { Link } from "react-router-dom";
import React from 'react';

const LOG = false;
const _ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

function CalendarTitle() {
  LOG && console.log("CalendarTitle");

  /** REDUX */

  /** LOCAL STATE */
  const [titleText, setTitleText] = useState("CALENDAR");

  const titleRandom = (hold = false) => {
    let iterations = 0;
    let interval: NodeJS.Timer;
    const updateTitle = () => {

      const el = document.getElementById("title")
      if(!el) return;
      const newText = 
        titleText
          .split("")
          .map(
            (letter, index) => {
              if(!hold && index < iterations) return letter;
              return(_ALPHABET[Math.floor(Math.random()*26)])
            }
          )
          .join("");

      el.innerText = newText+"+";

      if(iterations > titleText.length) clearInterval(interval);
      if(!hold) iterations += 1/3;
    };
    interval = setInterval(updateTitle, 50);
    return interval;
  }

  useEffect(
    () => {
      const interval = titleRandom();
      return () => {
        clearInterval(interval);
      };
    },
    []
  );

  return (
    <>
      <Link 
        to="/" 
        title="CalendarPlus Home"
        aria-label="CalendarPlus Home"
        className={styles.titlebutton2}
        onMouseMove={
          (e) => {
            const percent = ((e.currentTarget.offsetWidth - e.nativeEvent.offsetX) / e.currentTarget.offsetWidth) * 100;
            e.currentTarget.style.setProperty('--background-position-x', `${percent}%`)
          }
        }
      >
        <h1 id="title" className={styles.title2} unselectable="on">
          {titleText}+
        </h1>
        {/* {
          loggedIn &&
          <h1 
            className={styles.title2} 
            style={{
              // fontSize: "2rem",
              alignSelf: "center",
              paddingInline: "0.1rem",
              textTransform: "capitalize",
            }}
          
            unselectable="on"
          >
            {info.given_name}
          </h1>
        } */}
      </Link>
    </>
  );
}
export default React.memo(CalendarTitle);
