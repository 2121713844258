import { GoogleCalendarList } from './../../../../types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: { calendars: GoogleCalendarList } = {
  calendars: {
  }
}

export const calendarsSlice = createSlice({
  name: 'calendars',
  initialState,
  reducers: {
    load: (state, action: PayloadAction<GoogleCalendarList>) => {
      // console.log(`redux redux_calendars_load action: ${JSON.stringify(action)}`)
      if(!action.payload.items) return;

      const temp = [...action.payload.items];
      temp.sort(
        (a, b) => {
          if (a.primary === true) return -1;
          if (b.primary === true) return 1;
          if (a.accessRole === 'owner') return -2;
          if (b.accessRole === 'owner') return 1;
          else {
            const nameA = (a.summaryOverride || a.summary) as string;
            const nameB = (b.summaryOverride || b.summary) as string;
            if(nameA < nameB) return -1;
            else return 1;
          }
        }
      )

      state.calendars = {
        ...action.payload,
        items: temp
      };
    },
    clear: (state) => {
      // console.log(`redux redux_calendars_clear action: ${JSON.stringify(state)}`)
      state.calendars = initialState.calendars;
    }
  }
})

export const selectCalendars = (state: { calendars: typeof initialState; }): GoogleCalendarList => state.calendars.calendars;

export const redux_calendar_actions = calendarsSlice.actions
export default calendarsSlice.reducer